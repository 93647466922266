import React from 'react';
import { useStyles } from 'src/components/ApartmentPageV2/ApartmentLabels/styles';
import { appIcon } from 'src/assets';
import { IGroupProperty, IViewConfig } from 'src/typings/viewConfig';
import { getValueByPlacementSystemName } from 'src/helpers/placementSystemName';
import Box from '@mui/material/Box';
import { useBuildType, UseNumberFormat } from 'src/hooks';
import { BuildFileLink } from 'src/helpers/buildFileLink';

interface IApartmentLabels {
  viewConfig?: IViewConfig;
  viewConfigGroup: IGroupProperty;
}

export const ApartmentLabels: React.FC<IApartmentLabels> = ({
  viewConfig, viewConfigGroup,
}) => {
  const { isSpinner, isCrossTable } = useBuildType();
  const styles = useStyles({});
  const { numberSeparator, replaceFraction } = UseNumberFormat();
  return (
    <>
      <ul className={styles.apartmentLabels}>
        {viewConfig && viewConfigGroup?.propertySettings.map((setting, index) => {
          if (setting.placementProperty.systemName.indexOf('placementStatus') !== -1) {
            return (
              <li key={setting.id} className={styles.availability}>
                <span
                  className={styles.value}
                  style={{
                    fontWeight: setting.styles?.fontWeight,
                    color: setting.styles?.fontColor,
                    backgroundColor: getValueByPlacementSystemName(
                      viewConfig?.placement?.placementStatus,
                      isCrossTable ? 'crosstableColor' : 'color',
                      viewConfig?.lang,
                    )?.toString() || '',
                  }}
                />
                <Box
                  className={styles.title}
                  dangerouslySetInnerHTML={{
                    __html: `${setting.prefix ? setting.prefix[viewConfig.lang] || '' : ''}
                    ${numberSeparator(replaceFraction(getValueByPlacementSystemName(
                      viewConfig?.placement?.placementStatus,
                      isCrossTable ? 'crosstableName' : 'moduleName',
                      viewConfig?.lang,
                    )?.toString() || ''))}
                    ${setting.suffix ? setting.suffix[viewConfig.lang] || '' : ''}`,
                  }}
                />
              </li>
            );
          }
          const value = getValueByPlacementSystemName(viewConfig?.placement, setting.placementProperty.systemName, viewConfig?.lang);

          return (
            Boolean(value) && (
            <li key={setting.id} className={styles.valueAndIcon}>
              <span
                className={styles.value}
                style={{
                  backgroundSize: 'contain',
                  backgroundPosition: 'center center',
                  backgroundImage: `url(${setting.prefixIcon ? BuildFileLink(setting.prefixIcon) : ''})`,
                  backgroundRepeat: 'no-repeat',
                }}
              />
              <Box
                className={styles.title}
                dangerouslySetInnerHTML={{
                  __html: `${setting.prefix ? setting.prefix[viewConfig.lang] || '' : ''}
                  ${numberSeparator(replaceFraction(String(value)))}
                  ${setting.suffix ? setting.suffix[viewConfig.lang] || '' : ''}`,
                }}
              />
            </li>
            )
          );
        })}
      </ul>
    </>
  );
};
